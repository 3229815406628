
import React, { Component } from 'react';
import Loading from './Components/Loading';
import {FaChevronRight} from 'react-icons/fa';
import {genTrackingParametersForApi} from './Middlewares/UtmCid'

import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};


class Samsung extends Component {  
    
    constructor(props) {
        super(props);
     
        this.state = {
            isLoaded: false,
            hasError: false,
            lowest_price: {
                price_local:''
            },
            offers:[]
        }

    }
    componentDidMount(){
        //Init FB pixel 
        ReactPixel.init('629355881797954', advancedMatching, options);
        ReactPixel.pageView(); // For tracking page view
        //end FB 

        //Call API
        this.fetchOffers();
    }
    fetchOffers(){
        let url =  process.env.REACT_APP_API_URL+'/wtbo/52611';
        fetch(url, {
          method: 'POST', 
          headers: {
            "Content-Type": "application/json", 
            "X-Requested-With": "XMLHttpRequest"
          },
          body : JSON.stringify({
            'mpn' : 'qe50ls03tasxxn',
            'region' : 'NL',
            'parameters' : genTrackingParametersForApi()
          })
        })
        .then((res) => res.json())
        .then((result) => { 
            console.log(result);
              if(result){
                  this.setState({
                      isLoaded: true,
                      hasError: false,
                      lowest_price: result.lowest_price,
                      offers: Object.values(result.offers)
                  });
              }
              else {
                  this.setState({
                      isLoaded: true,
                      hasError: true
                  });
              }
          },
          (error) => {
              console.log(error.toString());
              this.setState({
                isLoaded: true,
                hasError:true
              });
          }
        )
    }

    
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderPage(this.props.match.params.option)) : (       
            <Loading />
        );            
    } 
    renderError() {  
        return (       
            <span>Oops ! Somethign went wrong, try to refresh the page or report it to help@gethatch.com</span>
        );            
    } 
    renderStock(param){
        switch(param) {
            case '0':
              return 'Out of stock';
            case '-99':
                return 'Pre-order';
            default:
              return 'In stock';
          }
    }
    renderCta(param,link){
        switch(param) {
            case '0':
              return this.renderNotiBtn()
            case '-99':
                return this.renderNotiBtn()
            default:
              return <a href={link} className="align-self-center color-black" target='_blank'><FaChevronRight /></a>
          }
    }


    renderPage() {  
        return (
            <div id="samsungframe" className="container-fluid px-0">
                <div className="row">
                    <div className="col-12">
                        <div className="image_placeholer"></div>
                    </div>
                </div>
                        
                <div id="wtb_offers_ctnr" className="container-fluid grid">
                    <div className="row">
                        <div className="col-12">
                                <div className="my-3">Buy online starting at <b>{this.state.lowest_price.price_local}</b></div>
                                <div className="wtb_offers">
                                    {this.state.offers.map((item,key) => {
                                    return (
                                            <div key={key} className="wtb_offer_ctnr">
                                                <a href={item.purchase_link} target='_blank' className="wtb_offer color-black">
                                                    <div className="wtb_offer_img"><img src={item.logo} className="align-self-center" /></div>
                                                    <div className="wtb_offer_price_stock">
                                                        <b>{item.price_local}</b>
                                                        <br />
                                                        {this.renderStock(item.stock_qty)}
                                                    </div>
                                                </a>
                                                <div className="d-flex wtb_offer_cta">{this.renderCta(item.stock_qty,item.purchase_link)}</div>
                                            </div>
                                            
                                        )
                                        
                                })}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default Samsung;