
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import React, { Component } from 'react';

import Home from './Home';
import Razer from './Razer';
import Quaker from './Quaker';
import Samsung from './Samsung';
import Cocacola from './Cocacola';
import Barilla from './Barilla';
import Lavazza from './Lavazza';
import Pampers from './Pampers';
import Intel from './Intel';

import './Scss/App.scss';

class App extends Component {
  render(){
    return (
        <div className="App">
          <Router>
            	{/* Watch out to */}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/razer" component={Razer} />
                <Route path="/quaker" component={Quaker} />
                <Route path="/samsungframe" component={Samsung} />
                <Route path="/cocacolazero" component={Cocacola} />
                <Route path="/barilla" component={Barilla} />
                <Route path="/lavazza" component={Lavazza} />
                <Route path="/intelevo" component={Intel} />
                
              </Switch>
            
            </Router>
        
        
          </div>
    )
  }
}

export default App;
