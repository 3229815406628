
import React, { Component } from 'react';
import Loading from './Components/Loading';
import {FaChevronRight} from 'react-icons/fa';

class Home extends Component {  
    
    constructor(props) {
        super(props);
     
        this.state = {
            isLoaded: false,
            hasError: false,
            lowest_price: {
                price_local:''
            },
            offers:[]
        }

    }
    componentDidMount(){
       
    }
    render() {  
        return (
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-12">
                      Nothing here

                    </div>
                </div>

            </div>
        )
    }
}

export default Home;