
export const genTrackingParametersForApi = () => {
    var url = window.location.search.substr(1); 
    var regexp = /([^&=]+)=?([^&]*)/g;
    var params = url.match(regexp);
    if(!params) return [];
    var ret = {};
    params.map(function(i,k) {
        var p = i.split('=');
        ret[p[0]] = p[1];
    });
    return ret;
    /*n_cid = n_cid.slice(0, -1);
    return decodeURIComponent(n_cid.replace(/\+/g, " "));*/


}
